import path from '@/api/path'
import service from '@/api/service'

const { file } = path

export default {
  // 文件上传-检查
  //https://uploadfile.wisemodel.cn/gateway/fileupload/api/v1/upload
  check: (data) => {
    return service.post(file.check, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  // 文件上传-分片上传
  upload: (data) => {
    return service.post(file.upload, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  // 文件上传-合并文件上传
  merge: (data) => {
    return service.post(file.merge, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  // 下载
  downupload: (data) => {
    const { project_id, filePath } = data
    return service.post(
      file.downupload + `?project_id=${project_id}&filename=${filePath}`,
      {},
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },
  // 创建文件夹
  createfolder: (data) => {
    return service.post(file.createfolder, data)
  },
  // 增加文件
  addfiles: (data) => {
    return service.post(file.addfiles, data)
  }
}
