<template>
  <div class="bg-white phonebody">
    <div class="text-37px font-bold text-center mt-23px phonetitle">
      欢迎使用始智AI平台
    </div>
    <div class="px-20px mt-35px ">
      <el-form ref="form" :model="form" :rules="formRule" label-width="50px" class="resform">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="telphone">
          <el-input v-model="form.telphone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="verificationCode">
          <div class="flex flex-1">
            <el-input v-model="form.verificationCode" placeholder="请输入动态码"></el-input>
            <get-verification-code-btn class="ml-16px" :telphone="form.telphone"
              :check-phone="true"></get-verification-code-btn>
          </div>
        </el-form-item>
        <el-form-item label="登录密码" prop="loginPsd">
          <el-input v-model="form.loginPsd" placeholder="请输入登录密码" type="password" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPsd">
          <el-input v-model="form.confirmPsd" placeholder="请输入确认密码" type="password" show-password></el-input>
        </el-form-item>
      </el-form>

      <div class="text-16px flex text-grey items-center ml-95px mt-23px fontsx">
        <el-checkbox v-model="agreeProtocol"></el-checkbox>
        <span class="  ml-9px cursor-pointer" @click="regFn">点击“注册”表示您已阅读并同意
          <span class="text-pri cursor-pointer" @click="visible = true">《用户协议与隐私政策》</span></span>
      </div>
      <el-button class="w-full h-53px mt-28px mb-0.2rem resbtn" type="primary" :loading="loading" @click="register"  data-umami-event="注册-提交" :data-umami-event-phone="form.telphone">注册</el-button>
    </div>
    <!-- 隐私协议弹层 -->
    <el-dialog :visible.sync="visible" width="8.3rem" append-to-body>
      <Protocol />
    </el-dialog>
  </div>
</template>

<script>
import Protocol from './protocol.vue'
import getVerificationCodeBtn from '@/components/get-verification-code-btn.vue'
import { init } from '@/utils/getSelectOptions'
import { valiUsername, valiPassword, valiMobilePhone } from '@/utils/validate'
import isEmail from "validator/lib/isEmail"

export default {
  components: { Protocol, getVerificationCodeBtn },
  data() {
    const usernameValidate = (rule, value, callback) => {
      const { status, message } = valiUsername(value)

      if (!status) {
        callback(message)
      } else {
        callback()
      }
    }
    const emailValidate = (rule, value, callback) => {
      const message = "非法邮箱格式"
      const status = isEmail(value)

      if (!status) {
        callback(message)
      } else {
        callback()
      }
    }
    const passwordValidate = (rule, value, callback) => {
      const { status, message } = valiPassword(value)

      if (!status) {
        callback(message)
      } else {
        callback()
      }
    }

    const mobilephoneValidate = (rule, value, callback) => {
      const { status, message } = valiMobilePhone(value)

      if (!status) {
        callback(message)
      } else {
        callback()
      }
    }

    const confirmPsdValite = (rule, value, callback) => {
      if (value !== this.form.loginPsd) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      form: {
        username: '',
        email: '',
        telphone: '',
        loginPsd: ''
      },
      formRule: {
        username: [
          { required: true, validator: usernameValidate, trigger: 'blur' }
        ],
        email: [{ required: true, validator: emailValidate, trigger: 'blur' }],
        telphone: [
          { required: true, validator: mobilephoneValidate, message: '手机号格式错误', trigger: 'blur' }
        ],
        verificationCode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ],
        loginPsd: [
          { required: true, validator: passwordValidate, trigger: 'blur' }
        ],
        confirmPsd: [
          { required: true, validator: confirmPsdValite, trigger: 'blur' }
        ]
      },
      agreeProtocol: false,
      visible: false,
      loading: false
    }
  },
  created() { },
  mounted() { },
  methods: {
    regFn(){
      this.agreeProtocol=!this.agreeProtocol
    },
    register() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.agreeProtocol) {
            this.$message.warning('请先阅读并同意相关协议')
            return
          }
          const {
            username,
            loginPsd: password,
            email,
            verificationCode: code,
            telphone: phone
          } = this.form
          this.loading = true
          this.$api.user
            .register({
              username,
              password,
              email,
              code,
              phone
            })
            .then((res) => {
              if (res.message === 'Success') {
                this.$message.success('注册成功')
                init() // 更新下拉选项信息
                this.$emit('register', {
                  username,
                  phone
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep {
  .el-input {
    height: 53px;
    font-size: 18px; /* Increase font size for mobile */
  }

  .el-form-item__label {
    height: 53px;
    line-height: 53px;
    font-size: 18px; /* Increase font size for mobile */
  }

  .el-checkbox__inner {
    width: 20px; /* Adjust checkbox size */
    height: 20px;
  }
}
::v-deep {
  .el-form .el-form-item{
    // display: block !important;
    margin-bottom: 0.1rem;
  }
    
}
@media only screen and (max-width: 767px) {
  .el-form .el-form-item {
    // display: block !important;
    margin-bottom: 0.2rem;
  }

  .el-checkbox__inner {
    width: 40px;
    height: 40px;
  }

  .ml-95px {
    margin-left: 0; 
  }

  .fontsx {
    margin-left: 0.3rem;
    transform: scale(1); 
    font-size: 18px; 
  }

  .phonebody {
    font-size: 24px; 
  }
  ::v-deep{
    
    .el-form .el-form-item__label{
      width: 100% !important;
      text-align: left !important;
      font-size: 0.35rem;
      margin-bottom: 0.1rem ;
    }
    .el-form .el-form-item{
      flex-wrap: wrap !important;
      margin-bottom: 0.3rem;
    }
    .el-input__suffix{
     top: 0.15rem;
     right: 0.2rem !important;
  }
  }
  .el-form{
    padding: 0 50px 0 50px;
  }
  .el-input {
    height: 1rem;
    font-size:0.35rem; 
  }
  .resbtn{
    height: 0.7rem;
    width: 50%;
    display: block;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
.phonetitle{
  font-size: 0.5rem;
}
}
::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }

</style>
